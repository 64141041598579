import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import { NotFoundPage as ErrorPage } from '@/shared-components/NotFound';

const NotFoundPage = () => {
  const router = useRouter();
  const { t } = useTranslation(['errors', 'common']);

  return (
    <>
      <Head>
        <title> {t('pageNotFound')}</title>
      </Head>
      <ErrorPage routerObject={router} />
    </>
  );
};

export const getStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale ?? 'en', ['errors', 'common'])),
    },
  };
};

export default NotFoundPage;
